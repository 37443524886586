import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    adminOrdersDataSelector,
  adminOrdersSumSelector,
  orderListAllSelector,
} from "./../data/selectors/orderListItemsSelector";
import { loadAdminMonthOrderListAction } from "./../data/actions/orderListActions";
import { userDataSelector } from "./../data/selectors/userDataSelector";
import AdminViewTableOrderList from "./../views/AdminViewTableOrderList";
import { loadManagerListAction } from "./../data/actions/managerListActions";
import { allManagerListSelector } from './../data/selectors/managerListSelector';
import { addUserLogDateApi } from "../api/logDataApi";


const AdminOrderListPerMonthContainer = () => {
  const dispatch = useDispatch();

  const userData = useSelector(userDataSelector);

  const currentMonth = userData.settingsData.monthData;
  const currentYear = userData.settingsData.yearData;
  const [month, setMonth] = React.useState(currentMonth);
  const [year, setYear] = React.useState(currentYear);
  const [managerId, setManagerId] = React.useState(0);
  const [location, setLocation] = React.useState("uk-UA");

  const { items, ordersSum } = useSelector(adminOrdersDataSelector(managerId));
  const managerList = useSelector(allManagerListSelector);

  const handleChangeMonth = (event) => {
    setMonth(event.target.value);
  };

  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };

  const handleChangeManager = (event) => {
    setManagerId(event.target.value);
  };

  React.useEffect(() => {
    dispatch(loadAdminMonthOrderListAction(userData, year, month, location));
  }, [month, year]);

  React.useEffect(() => {
    dispatch(loadManagerListAction(userData));
    addUserLogDateApi(userData, '/admin/orderListReport')
  }, []);

  return (
    <AdminViewTableOrderList
      items={items}
      month={month}
      year={year}
      managerId={managerId}
      managerList={managerList}
      location={location}
      setLocation={setLocation}
      userData={userData}
      ordersSum={ordersSum}
      handleChangeMonth={handleChangeMonth}
      handleChangeYear={handleChangeYear}
      handleChangeManager={handleChangeManager}
    />
  );
};

export default AdminOrderListPerMonthContainer;
