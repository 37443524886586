import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { userDataSelector } from '../data/selectors/userDataSelector';
import { getAllManagerSaleListAction, getManagerSaleListAction, loadManagerListAction } from './../data/actions/managerListActions';
import { allManagerListSelector, managerListSalesTotalSumSelector, managerSalesTotalSumSelector } from './../data/selectors/managerListSelector';
import AdminProductQtyList from './../views/AdminProductQtyList';
import { productQtyListTableSelector } from '../data/selectors/productQtySelector';
import { uniqueProductQtyListSourcesSelector } from './../data/selectors/productQtySelector';
import { getProductQtyListAction, updateProductQtyAction } from '../data/actions/productQtyListActions';
import { addUserLogDateApi } from '../api/logDataApi';
import ProductQtyList from '../views/ProductQtyList';

const ProductPlanContainer = () => {


    const dispatch = useDispatch()

    const userData = useSelector(userDataSelector)

    const managerIdData = userData.managerId

    const currentMonth = userData.settingsData.monthData
    const currentYear = userData.settingsData.yearData
    const [month, setMonth] = useState(currentMonth)
    const [year, setYear] = useState(currentYear)
    const [sourceData, setSourceData] = useState(0)
    const [editedData, setEditedData] = useState({})

    const productQtyList = useSelector(productQtyListTableSelector(sourceData))
    // const uniqueProductQtySources = useSelector(uniqueProductQtyListSourcesSelector)
    const managerListSalesTotalSum = useSelector(managerSalesTotalSumSelector(year))

    const handleChangeMonth = (event) => {
        setMonth(event.target.value)
    }
    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }

    const data = { userData, year, month, managerIdData, editedData }

    const getSalesTotals = (selectedManagerId, data, selectedMonth, productQtyList) => {
        let shopSum = 0;
        let aptekSalesSum = 0;
        let clinicSalesSum = 0;
        let minOklad = 0;

        const totalCompletePercent = Number(productQtyList.totalСomplPercent)

        data.forEach(manager => {
            if (selectedManagerId && manager.managerId !== selectedManagerId) {
                return;
            }

            manager.monthlySales.forEach(sale => {
                if (selectedMonth && sale.month !== selectedMonth) {
                    return;
                }

                const shopSumItem = sale.saleSum + sale.otherSale
                shopSum += shopSumItem || 0;
                aptekSalesSum += sale.aptekSale || 0;
                clinicSalesSum += sale.clinicSale || 0;
                minOklad += sale.minOklad || 0;
            });
        });

        let currentBonusPercent = 0;

        switch (true) {
            case totalCompletePercent >= 100:
                currentBonusPercent = 7
                break;
            case totalCompletePercent >= 90:
                currentBonusPercent = 6
                break;
            case totalCompletePercent < 90:
                currentBonusPercent = 5
                break;

            default:
                break;
        }

        const totalVal = shopSum + clinicSalesSum + aptekSalesSum

        const bonusRes = Math.round(totalVal * (currentBonusPercent / 100))

        const totalBonus = bonusRes + minOklad

        return { shopSum, aptekSalesSum, clinicSalesSum, totalVal, minOklad, bonusRes, totalBonus };
    };


    const managerSalesTotal = getSalesTotals(managerIdData, managerListSalesTotalSum, month, productQtyList)

    useEffect(() => {
        dispatch(getProductQtyListAction(data))
    }, [month, year, managerIdData])

    useEffect(() => {
        dispatch(getManagerSaleListAction(userData, year))
        addUserLogDateApi(userData, '/productQtyListReport')
    }, [year])

    return (
        // <div>111</div>
        <ProductQtyList
            items={productQtyList}
            month={month}
            year={year}
            managerId={managerIdData}
            userData={userData}
            sourceData={sourceData}
            managerSalesTotal={managerSalesTotal}
            setEditedData={setEditedData}
            handleChangeMonth={handleChangeMonth}
            handleChangeYear={handleChangeYear}
        />
    )
}

export default ProductPlanContainer
