import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { userDataSelector } from '../data/selectors/userDataSelector';
import { allManagerListSelector } from './../data/selectors/managerListSelector';
import { loadManagerListAction } from './../data/actions/managerListActions';
import { loadManagerDebetListAction } from './../data/actions/debetsActions';
import { debetListSelector } from './../data/selectors/debetListSelector';
import AdminViewTableDebetList from './../views/AdminViewTableDebetList';
import { addUserLogDateApi } from '../api/logDataApi';

const AdminDebetListReportContainer = () => {

    const dispatch = useDispatch()

    const userData = useSelector(userDataSelector)
    const currentMonth = userData.settingsData.monthData
    const currentYear = userData.settingsData.yearData
    const [month, setMonth] = useState(currentMonth)
    const [year, setYear] = useState(currentYear)
    const [managerId, setManagerId] = useState(0)
    const [location, setLocation] = useState('uk-UA');
    const [archiveStatus, setArchiveStatus] = useState(true);
    const debetList = useSelector(debetListSelector)
    console.log("🚀 ~ file: AdminDebetListReportContainer.js:33 ~ AdminDebetListReportContainer ~ debetList:", debetList)
    const managerList = useSelector(allManagerListSelector)


    const handleChangeMonth = (event) => {
        setMonth(event.target.value)
    }
    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }
    const handleChangeManager = (event) => {
        setManagerId(event.target.value)
    }
    const handleChangeArchive = (event) => {
        setArchiveStatus(event.target.value)
    }

    useEffect(() => {
        dispatch(loadManagerDebetListAction(userData, year, month, managerId))
    }, [month, year, managerId, location])

    useEffect(() => {
        dispatch(loadManagerListAction(userData, location))
        addUserLogDateApi(userData, '/admin/debetListReport')
    }, [])

    return (
        <AdminViewTableDebetList
            data={debetList}
            month={month}
            year={year}
            managerId={managerId}
            managerList={managerList}
            userData={userData}
            handleChangeMonth={handleChangeMonth}
            handleChangeYear={handleChangeYear}
            handleChangeManager={handleChangeManager}
        />

    )
}

export default AdminDebetListReportContainer
