import { AppBar, makeStyles, Toolbar, Typography, Button, Link, Divider, Grid } from "@material-ui/core"
import DashboardManagerSalesContainer from '../containers/DashboardManagerSalesContainer';
import { useSelector } from 'react-redux';
import { userDataSelector } from "../data/selectors/userDataSelector";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    managerName: {
    },
    title: {
        flexGrow: 1,
    },
    link: {
        color: '#ffffff',
        marginRight: theme.spacing(2),
        // lineHeight: 1.6
    },
    menu: {
        position: 'relative',
        top: 5
    }
}));

const AdminHeaderLayout = () => {
    const classes = useStyles();

    const userData = useSelector(userDataSelector)

    const preventDefault = (event) => event.preventDefault();

    return (
        <AppBar position="fixed">
            <Toolbar>
                <Grid container spacing={3}>
                    <Grid item xs={1}>
                        <Typography variant="h6" className={classes.managerName}>
                            PeroliteApp
                        </Typography>
                    </Grid>
                    <Grid item xs={8} className={classes.menu}>
                        <Link href="/admin/dashboard" className={classes.link}>
                            Дашборд
                        </Link>
                        <Link href="/admin/allPromocodes" className={classes.link}>
                            Все промокоды
                        </Link>
                        <Link href="/admin/doctorsBonusReport" className={classes.link}>
                            Архив бонусов докторов
                        </Link>
                        <Link href="/admin/orderListReport" className={classes.link}>
                            Заказы
                        </Link>
                        <Link href="/admin/productQtyListReport" className={classes.link}>
                            План
                        </Link>
                        <Link href="/admin/debetListReport" className={classes.link}>
                            Дебет
                        </Link>
                        <Link href="/"
                        className={classes.link}
                        onClick={() => localStorage.removeItem('user')}
                        >
                            Выйти
                        </Link>
                    </Grid>
                    {/* <Grid item xs={3}>
                        <Typography variant="h6" align='right' className={classes.managerName}>
                            Менеджер: {userData.managerName}
                        </Typography>
                    </Grid> */}
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

export default AdminHeaderLayout