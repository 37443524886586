import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { FormControl, InputLabel, MenuItem, Select, Typography, TextField, Divider } from '@material-ui/core';

import AdminProductQtyEditableField from './AdminProductQtyEditableField';

const useStyles = makeStyles({
  table: {
    minWidth: 1000,
    position: 'relative',
    top: -11
  },
  container: {
    maxHeight: 1000,
    padding: '0 0 10px',
    overflow: 'visible',
    marginTop: 122
  },
  stickyHeader: {
    backgroundColor: '#e8ebf7',
    position: "sticky",
    top: 112,
    zIndex: 100,
    boxShadow: '1px 1px 1px #ccc'
  },
  formControl: {
    width: 110,
    marginLeft: 10
  },
  adminOrdersSumStyle: {
    width: 250,
    marginLeft: 10,
    position: "relative",
    top: 17
  },
  filterRow: {
    backgroundColor: '#e8ebf7',
    width: '100%',
    display: 'inline-block',
    position: 'fixed',
    top: 64,
    zIndex: 101,
    borderBottom: '1px solid #ccc'
  },
});

const AdminProductQtyList = (props) => {
  const { items, month, year, managerId, managerList, userData, uniqueProductQtySources, sourceData, managerSalesTotal, setEditedData, handleChangeMonth, handleChangeYear, handleChangeManager, handleChangeSource } = props

  const classes = useStyles();

  return (
    <>
      <div className={classes.filterRow}>
        <FormControl className={classes.formControl}>
          <InputLabel id="month label">Месяц</InputLabel>
          <Select
            value={month}
            onChange={handleChangeMonth}
          >
            <MenuItem value={12}>12</MenuItem>
            <MenuItem value={11}>11</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={1}>1</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="year label">Год</InputLabel>
          <Select
            value={year}
            onChange={handleChangeYear}
          >
            <MenuItem value={2023}>2023</MenuItem>
            <MenuItem value={2022}>2022</MenuItem>
            <MenuItem value={2021}>2021</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="manager label">Менеджер</InputLabel>
          <Select
            value={managerId}
            onChange={handleChangeManager}
          >
            {managerList.map(item => {
              return <MenuItem value={item.id}>{item.managerName}</MenuItem>
            })}
          </Select>
        </FormControl>
        {/* <FormControl className={classes.formControl}>
          <InputLabel id="source label">Источник</InputLabel>
          <Select
            value={sourceData}
            onChange={handleChangeSource}
          >
            {uniqueProductQtySources.map(item => {
              return <MenuItem value={item}>{item}</MenuItem>
            })}
          </Select>
        </FormControl> */}
        <FormControl className={classes.formControl}>
        <TextField
          id="standard-disabled"
          label="Выполнение: "
          value={`${items.totalСomplPercent} %`}
          color="secondary"
        />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Вал магазин"
            id="val-shop-text"
            value={managerSalesTotal.shopSum}
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Вал опт"
            id="val-clinics-text"
            value={managerSalesTotal.clinicSalesSum}
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Вал аптеки"
            id="val-apteks-text"
            value={managerSalesTotal.aptekSalesSum}
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Вал общий"
            id="val-total-text"
            value={managerSalesTotal.totalVal}
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Бонус"
            id="val-bonus-text"
            value={managerSalesTotal.bonusRes}
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Ставка"
            id="val-minOklad-text"
            value={managerSalesTotal.minOklad}
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Общий бонус"
            id="total-bonus-text"
            value={managerSalesTotal.totalBonus}
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
      </div>
      <TableContainer component={Paper} className={classes.container} elevation={0}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead className={classes.stickyHeader}>
            <TableRow>
              <TableCell>Менеджер</TableCell>
              <TableCell>Артикул препарата</TableCell>
              {/* <TableCell>Название препарата</TableCell> */}
              <TableCell>Плановое к-во</TableCell>
              <TableCell>Факт. общее</TableCell>
              <TableCell>Факт. магазин</TableCell>
              <TableCell>Факт. клиники</TableCell>
              <TableCell>Факт. аптеки</TableCell>
              <TableCell>Выполнение плана (%)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.productQtyArr.sort((a, b) => a['productArticle'].localeCompare(b['productArticle'])).map((item) => (
              <TableRow key={`${item.id}-pqtyItem`}>
                <TableCell component="th" scope="row">
                  <Typography variant="body2"
                    style={{
                      maxWidth: 100,
                      // whiteSpace: 'pre-wrap',
                      // overflow: 'hidden',
                      // textOverflow: 'ellipsis'
                    }}
                    title={item.manager.managerName}
                  >
                    {item.manager.managerName}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography variant="body2"
                    style={{
                      maxWidth: 200,
                      // whiteSpace: 'pre-wrap',
                      // overflow: 'hidden',
                      // textOverflow: 'ellipsis'
                    }}
                    title={item.productArticle}
                  >
                    {item.productArticle}
                  </Typography>
                </TableCell>
                {/* <TableCell component="th" scope="row">
                  <Typography variant="body2"
                    style={{
                      maxWidth: 200,
                      // whiteSpace: 'pre-wrap',
                      // overflow: 'hidden',
                      // textOverflow: 'ellipsis'
                    }}
                    title={item.productTitle}
                  >
                    {item.productTitle}
                  </Typography>
                </TableCell> */}
                <TableCell component="th" scope="row">
                  <AdminProductQtyEditableField
                    key={`${item.id}-planQty`}
                    id={item.id}
                    value={item.planQty}
                    setEditedData={setEditedData}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography
                    variant="body2"
                    style={{
                      maxWidth: 100,
                      whiteSpace: 'pre-wrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                    {item.factQtyTotal}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography
                    variant="body2"
                    style={{
                      maxWidth: 100,
                      whiteSpace: 'pre-wrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                    {item.factQtyS}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography
                    variant="body2"
                    style={{
                      maxWidth: 100,
                      whiteSpace: 'pre-wrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                    {item.factQtyC}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography
                    variant="body2"
                    style={{
                      maxWidth: 100,
                      whiteSpace: 'pre-wrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                    {item.factQtyA}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography
                    variant="body2"
                    style={{
                      maxWidth: 100,
                      whiteSpace: 'pre-wrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                    {item.completionRateItem}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default AdminProductQtyList