import { createSelector } from 'reselect'
import { orderListItemsSelector } from './orderListItemsSelector';
import { managerSalesFinLimitSelector } from './managerListSelector';
import { userDataSelector } from './userDataSelector';
import { settingsDataSelector } from './settingsDataSelector';

export const promocodeListSelector = (state) => state.promocode.items
export const promocodeBonusListSelector = (state) => state.promocode.bonuses

export const newPromocodeListSelector = createSelector(
    [promocodeListSelector],
    (promocodeList) => {
        const newPromocodes = promocodeList.filter(item => item.activated === false)
        return newPromocodes
    }
)

export const allPromocodeListSelector = createSelector(
    [promocodeListSelector],
    (promocodeList) => {

        const arrays = Object.values(promocodeList)
        var res = [].concat.apply([], arrays);

        let sortedPromocodes = [...res];
        sortedPromocodes.sort((b, a) => {
            if (a.manager !== null && b.manager !== null && a.manager.id > b.manager.id) {
                return -1;
            }
            if (a.manager !== null && b.manager !== null && a.manager.id < b.manager.id) {
                return 1;
            }
            return 0;
        })
        return sortedPromocodes
    }
)

export const newPromocodeBonusListSelector = createSelector(
    [promocodeBonusListSelector, userDataSelector],
    (promocodeBonusList, userData) => {
        const { monthData, yearData } = userData.settingsData

        const filterList = (item) => {
            if (item.bonusMonth === monthData && item.bonusYear === yearData) {
                return item
            }
        }
        const newPromocodeBonusList = promocodeBonusList.filter(filterList)
        return newPromocodeBonusList
    }
)
export const newPromocodeBonusAllSumSelector = createSelector(
    [newPromocodeBonusListSelector, userDataSelector, managerSalesFinLimitSelector],
    (newPromocodeBonusList, userData, managerSalesFinLimit) => {
        const allBonusSum = newPromocodeBonusList.reduce((sum, item) => sum + item.bonusSum, 0)
        const allBonusExtra = newPromocodeBonusList.reduce((sum, item) => sum + item.bonusExtra, 0)
        const allBonusTotal = allBonusSum + allBonusExtra
        const month = userData.settingsData.monthData
        return { allBonusSum, allBonusExtra, allBonusTotal, month, managerSalesFinLimit }
    }
)

export const activatedPromocodeBonusListSelector = (archiveStatus) => createSelector(
    [promocodeBonusListSelector],
    (promocodeBonusList) => {
        const activatedList = promocodeBonusList.filter(item => item.activated === archiveStatus)
        return activatedList
    }
)