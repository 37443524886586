import { createSelector } from 'reselect';
import date from 'date-and-time';

export const userSelector = (state) => state.authentication.user

export const userDataSelector = createSelector(
    [userSelector],
    (user) => {
        const token = user.jwt
        const managerId = user.user.manager_id.id
        const managerLocale = user.user.manager_id.locale
        const managerName = user.user.manager_id.managerName
        const managerBonusPercent = user.user.manager_id.bonusPercent
        let d = new Date();
        d.setDate(1);
        d.setMonth(d.getMonth() - 1);
        const monthData = Number(date.format(d, 'M'));
        const yearData = Number(date.format(d, 'YYYY'));
        const settingsData = { monthData, yearData }
        const role = user.user.role.name
        return { token, managerId, managerLocale, managerName, settingsData, managerBonusPercent, role }
    }
)

// export const adminDataSelector = createSelector(
//     [userSelector],
//     (user) => {
//         const token = user.jwt
//         let d = new Date();
//         d.setDate(1);
//         d.setMonth(d.getMonth() - 1);
//         const monthData = Number(date.format(d, 'M'));
//         const yearData = Number(date.format(d, 'YYYY'));
//         const settingsData = { monthData, yearData }
//         const role = user.user.role.name
//         const managerId = user.user.manager_id.id
//         const adminLocale = user.user.manager_id.locale
//         return { token, settingsData, role, adminLocale, managerId }
//     }
// )