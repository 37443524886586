import React, { useState } from "react";
import Papa from "papaparse";
import axios from "axios";
import PropTypes from "prop-types";
import { csvToJsonDebetListHelper, isDataMatching } from './../data/helpers/csvToJsonDataHelpers';

const CsvUploader = ({ apiUrl, existingItems, itemKey, token, collectionType, factQtyField }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [csvData, setCsvData] = useState([]);

  let fieldsToCompare;

  switch (collectionType) {
    case 'products-quantities':
      fieldsToCompare = ['year', 'month', 'productArticle', 'manager'];
      break;
    default:
      break;
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    Papa.parse(file, {
      header: true,
      complete: (results) => {
        setCsvData(results.data);
      },
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      let isDataUpdated = false;
      for (const csvItem of csvData) {
        const resItem = csvToJsonDebetListHelper(csvItem, collectionType, factQtyField);

        const existingItem = existingItems.find(
          (item) => {
            return isDataMatching(item, resItem, fieldsToCompare, factQtyField);
          }
        );

        if (existingItem) {
          // Вывод идентификатора существующего элемента
          console.log(`Existing item id: ${existingItem.id}`);

          // Обновление поля фактического количества
          if (existingItem[factQtyField] !== resItem[factQtyField]) {
            // Отправка обновленных данных на сервер
            // console.log("🚀 ~ file: CsvUploader.js:56 ~ handleFormSubmit:", existingItem.id, existingItem.manager.managerName,[factQtyField], resItem[factQtyField])
            await axios.put(`${apiUrl}/${existingItem.id}`, { [factQtyField]: resItem[factQtyField] }, {
              headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: `Bearer ${token}`,
              },
            });

            console.log(`Item with name ${resItem.manager.id}, ${resItem.productArticle}, ${resItem[factQtyField]} updated`);
            isDataUpdated = true;
          }
        } else {
          // Отправка новых данных на сервер
          await axios.post(apiUrl, resItem, {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              Authorization: `Bearer ${token}`,
            },
          });

          console.log(`New item with name created`);
          isDataUpdated = true;
        }
      }

      if (isDataUpdated) {
        alert("Данные успешно загружены");
      } else {
        alert("Данные в CSV-файле совпадают с существующими данными");
      }
    } catch (error) {
      console.error(error);
      alert("Ошибка загрузки данных на сервер");
    }
  };

  return (
    <div>
      <form onSubmit={handleFormSubmit}>
        <label htmlFor="file-upload">Выберите CSV-файл для загрузки:</label>
        <input
          id="file-upload"
          type="file"
          accept=".csv"
          onChange={handleFileUpload}
        />
        <button type="submit" disabled={!selectedFile}>
          Загрузить и отправить данные
        </button>
      </form>
    </div>
  );
};

CsvUploader.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  existingItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  itemKey: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  factQtyField: PropTypes.string.isRequired,
};

export default CsvUploader;
