import React from "react";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { ACTIVE_PROMOCODE_STATUS } from "../data/constants/statuses";
import { NEW_PROMOCODE_STATUS } from './../data/constants/statuses';
import { Button, makeStyles } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableCell: {
    padding: '6px 8px 6px 8px'
  },
  button: {
    margin: theme.spacing(0),
    padding: 2,
    minWidth: 20
  }
}));

const AdminPromocodeReadRow = ({ item, itemId, status, handleEditClick, handleActivateClick }) => {
// console.log("🚀 ~ file: AdminPromocodeReadRow.js ~ line 25 ~ AdminPromocodeReadRow ~ item", item.manager)
  const classes = useStyles();

  return (
    <TableRow key={itemId}>
      <TableCell component="th" scope="row">
        {item.manager === null ? 'Не назначен менеджер' : item.manager.managerName}
      </TableCell>
      <TableCell component="th" scope="row" className={classes.tableCell}>
        {item.promocode}
      </TableCell>
      <TableCell className={classes.tableCell}>{item.doctor}</TableCell>
      <TableCell className={classes.tableCell}>{item.clinic}</TableCell>
      <TableCell className={classes.tableCell}>{item.city}</TableCell>
      <TableCell className={classes.tableCell}>{item.region}</TableCell>
      <TableCell className={classes.tableCell}>{item.phone}</TableCell>
      <TableCell className={classes.tableCell}>{item.cardNumber}</TableCell>
      {/* <TableCell component="th" scope="row" className={classes.tableCell}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          className={classes.button}
          onClick={(event) => handleEditClick(event, item, itemId)}
        >
          <EditIcon />
        </Button>
      </TableCell> */}
      {/* {status === NEW_PROMOCODE_STATUS && <TableCell component="th" scope="row" className={classes.tableCell}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          className={classes.button}
          onClick={(event) => handleActivateClick(event, item, itemId)}
        >
          <SendIcon />
        </Button>
      </TableCell>
      } */}
    </TableRow>
  );
};

export default AdminPromocodeReadRow;