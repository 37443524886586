import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DashboardTableRow from './DashboardTableRow';
import { Typography } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles({
  table: {
    minWidth: 1000,
    position: 'relative',
    top: -11
  },
  container: {
    maxHeight: 1000,
    padding: '0 0 10px',
    overflow: 'visible',
    marginTop: 122
  },
  tableRow: {
    backgroundColor: '#e8ebf7',
  },
  stickyHeader: {
    backgroundColor: '#e8ebf7',
    position: "sticky",
    top: 112,
    zIndex: 100,
    boxShadow: '1px 1px 1px #ccc'
  },
  formControl: {
    width: 140,
    marginLeft: 10
  },
  managerName: {
    marginTop: 20,
    marginLeft: 15
  },
  filterRow: {
    backgroundColor: '#e8ebf7',
    width: '100%',
    display: 'inline-block',
    position: 'fixed',
    top: 64,
    zIndex: 101,
    borderBottom: '1px solid #ccc'
  },
});

const AdminViewTableManagersTotalSale = (props) => {

  const {
    managers,
    userData,
    setLocation,
    year,
    handleChangeYear
  } = props
  const classes = useStyles();

  return (
    <>
      <div className={classes.filterRow}>
        <FormControl className={classes.formControl}>
          <InputLabel id="year label">Год</InputLabel>
          <Select
            value={year}
            onChange={handleChangeYear}
          >
            <MenuItem value={2023}>2023</MenuItem>
            <MenuItem value={2022}>2022</MenuItem>
            <MenuItem value={2021}>2021</MenuItem>
          </Select>
        </FormControl>
      </div>
      <TableContainer component={Paper} className={classes.container} elevation={0}>
        <Table className={classes.table} aria-label="a dense table">
          <TableHead className={classes.stickyHeader}>
            <TableRow className={classes.tableRow}>
              <TableCell></TableCell>
              <TableCell>Январь</TableCell>
              <TableCell>Февраль</TableCell>
              <TableCell>Март</TableCell>
              <TableCell>Апрель</TableCell>
              <TableCell>Май</TableCell>
              <TableCell>Июнь</TableCell>
              <TableCell>Июль</TableCell>
              <TableCell>Август</TableCell>
              <TableCell>Сентябрь</TableCell>
              <TableCell>Октябрь</TableCell>
              <TableCell>Ноябрь</TableCell>
              <TableCell>Декабрь</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {managers.map(item => {
              const managerSales = item.monthlySales.sort((a, b) => a.month > b.month)
              return (
                <>
                  <TableHead><Typography variant="h6" gutterBottom className={classes.managerName}>
                    {item.managerName}
                  </Typography>
                  </TableHead>
                  <>
                    <DashboardTableRow items={managerSales} title={'Интернет-продажи с промокодами'} fieldName={'saleSum'} rowColor='blue' year={year}/>
                    <DashboardTableRow items={managerSales} title={'Интернет-продажи без промокодов'} fieldName={'otherSale'} rowColor='blue' year={year}/>
                    <DashboardTableRow items={managerSales} title={'Аптеки'} fieldName={'aptekSale'} isEditable userData={userData} rowColor='blue' year={year}/>
                    <DashboardTableRow items={managerSales} title={'Клиники'} fieldName={'clinicSale'} isEditable userData={userData} rowColor='blue' year={year}/>
                    <DashboardTableRow items={managerSales} title={'Общая сумма продаж'} fieldName={'totalSaleSum'} rowColor='blue' year={year}/>
                    <DashboardTableRow items={managerSales} title={'Бонус менеджера'} fieldName={'managerBonusSum'} rowColor='green' year={year}/>
                    <DashboardTableRow items={managerSales} title={'Миноклад'} fieldName={'minOklad'} isEditable userData={userData} rowColor='green' year={year}/>
                    <DashboardTableRow items={managerSales} title={'Общий доход менеджера'} fieldName={'managerProfit'} rowColor='green' rowFont='bold' year={year}/>
                  </>
                  <Divider />
                </>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default AdminViewTableManagersTotalSale