import { CURRENT_PROMOCODE_ID_UPDATE, PROMOCODE_LIST_LOAD_SUCCESSFUL, PROMOCODE_ITEM_UPDATE, PROMOCODE_BONUS_LIST_LOAD_SUCCESSFUL } from '../reducers/promocodeReducer';
import { getManagerPromocodeListApi, getManagerPromocodeSumListApi, updatePromocodeByIdApi, getManagerPromocodeBonusListApi, updatePromocodeBonusByIdApi, getAllManagerPromocodeBonusListApi, getAllManagersPromocodeListApi } from './../../api/promocodesApi';

export const loadManagerPromocodeListAction = (userData) => {
    return async (dispatch) => {
        const data = await getManagerPromocodeListApi(userData)
        dispatch({
            type: PROMOCODE_LIST_LOAD_SUCCESSFUL,
            payload: data,
        })
    }
}

export const loadAllManagersPromocodeListAction = (userData, managerId, location) => {
    return async (dispatch) => {
        const data = await getAllManagersPromocodeListApi(userData, managerId, location)
        dispatch({
            type: PROMOCODE_LIST_LOAD_SUCCESSFUL,
            payload: data,
        })
    }
}

export const loadManagerPromocodeBonusListAction = (userData, filterMonth, filterYear) => {
    return async (dispatch) => {
        const data = await getManagerPromocodeBonusListApi(userData, filterMonth, filterYear)
        dispatch({
            type: PROMOCODE_BONUS_LIST_LOAD_SUCCESSFUL,
            payload: data,
        })
    }
}

export const loadAllManagersPromocodeBonusListAction = (userData, year, month, managerId, location) => {
    return async (dispatch) => {
        const data = await getAllManagerPromocodeBonusListApi(userData, year, month, managerId, location)
        dispatch({
            type: PROMOCODE_BONUS_LIST_LOAD_SUCCESSFUL,
            payload: data,
        })
    }
}

export const setCurrentPromocodeIdAction = (id) => {
    return async (dispatch) => {
        dispatch({
            type: CURRENT_PROMOCODE_ID_UPDATE,
            payload: id,
        })
    }
}

export const updatePromocodeItemAction = (id, data, userData) => {
    return async (dispatch) => {
        const res = await updatePromocodeByIdApi(id, data, userData)
        if (!res) return
        dispatch(loadManagerPromocodeListAction(userData))
    }
}

export const updatePromocodeBonusAction = (id, data, userData) => {
    return async (dispatch) => {
        const res = await updatePromocodeBonusByIdApi(id, data, userData)
        if (!res) return
        dispatch(loadManagerPromocodeBonusListAction(userData))
    }
}

