import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    const userData = localStorage.getItem('user')
    const user =JSON.parse(userData)
    const role = user ? user.user.role.type : ''
    return (
        <Route {...rest} render={props => {
            if (!localStorage.getItem('user')) {

                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }
            if (localStorage.getItem('user') && role === 'backadmin') {
              
                return <Redirect to={{ pathname: '/admin/dashboard' }} />
            }

            // logged in so return component
            return <Component {...props} />
        }} />
    );
}

export { PrivateRoute };