import axios from 'axios'
import { BACKEND_DOMAIN } from './../data/constants/statuses';

export const getUpdateDataApi = async (userData, typeValue) => {
    const { token } = userData

    const res = axios
        .get(`${BACKEND_DOMAIN}/update-data/updateData?type=${encodeURIComponent(typeValue)}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    console.log("🚀 ~ file: updateDataApi.js ~ line 18 ~ getUpdateDataApi ~ res", res)
    return res;
}
