import { createSelector } from 'reselect'

export const productQtyListSelector = (state) => state.productQtyList.productQty

export const productQtyListTableSelector = (source) => createSelector(
  [productQtyListSelector],
  (productQtyList) => {
    let productQtyArr = []
    let acceptablePercСomplRelativeTotalArr = []
    const articleListFilter = productQtyList.filter(item => item.planQty > 0)
    const articleListLenght = articleListFilter.length

    productQtyList.forEach(item => {

      const factQtyTotal = item.factQtyS + item.factQtyC + item.factQtyA

      const completionRateItem = item.planQty > 0 && factQtyTotal > 0 ? ((factQtyTotal / item.planQty).toFixed(4) * 100) : 0

      const completionRateRelativeTotal = completionRateItem / articleListLenght

      const threshold = 100 / articleListLenght

      const acceptablePercСomplRelativeTotal = completionRateRelativeTotal >= threshold ? threshold : completionRateRelativeTotal

      const newItem = { ...item, completionRateItem: completionRateItem, K: acceptablePercСomplRelativeTotal, L: threshold, factQtyTotal: factQtyTotal }
      productQtyArr.push(newItem)
      acceptablePercСomplRelativeTotalArr.push(acceptablePercСomplRelativeTotal)


    });
    const totalСomplPercent = acceptablePercСomplRelativeTotalArr.reduce((sum, i) => sum + i, 0).toFixed(2)
    console.log("🚀 ~ file: productQtySelector.js:30 ~ totalСomplPercent:", totalСomplPercent)

    return { productQtyArr, totalСomplPercent }
  }
)

// export const uniqueProductQtyListSourcesSelector = createSelector(
//   [productQtyListTableSelector],
//   (productQtyList) => {
//     // const sources = productQtyList.productQtyArr.map((item) => item.source);
//     // const uniqueSources = [...new Set(sources)];
//     // uniqueSources.unshift("Все источники");
//     // return uniqueSources;
//   }
// );

