import axios from 'axios';
import { BACKEND_DOMAIN } from './../data/constants/statuses';

export const getManagerDebetListApi = async (userData, filterYear, filterMonth, managerId) => {
  const { token, managerLocale, settingsData } = userData

  const month = filterMonth ? filterMonth : settingsData.monthData
  const year = filterYear ? filterYear : settingsData.yearData
  const manager = managerId === 0 ? '' : `&manager=${managerId}`

  const request = filterMonth ? `${manager}&month=${month}&year=${year}` : ''


  const res = axios
    .get(`${BACKEND_DOMAIN}/debets?_limit=-1${request}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        return response.data
      });
  return res;
}